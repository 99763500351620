import Footer from "Components/Footer";
import Header from "Components/Header";
import About from "pages/About";
import Contact from "pages/Contact";
import Report from "pages/Report";
import DashBoard from "pages/DashBoard";
import Data from "pages/Data";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RouteProtector from "Components/RouteProtector";
import Shop from "pages/Shop/Index";

export default function AppRouter() {
    return (
        <Router>
            <Header/>
            <Routes>
                <Route path="/" element={<About/>}/>
                <Route path="/data" element={<Data/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/shop" element={<Shop/>}/>
                <Route path="/dashboard" element={
                    <RouteProtector>
                        <DashBoard/>
                    </RouteProtector>
                }/>
                <Route path="/report" element={
                    <RouteProtector>
                        <Report/>
                    </RouteProtector>
                }/>
            </Routes>
            <Footer/>
        </Router>
    )
}