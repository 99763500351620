import styles from "./Data.module.scss"
import TitleDescription from "Components/TitleDescription";
import { Chart } from "react-google-charts";

export const data = [
    ["Achievements", "2023", "2024", "2025"],
    ["Patents", 2, 4, 5],
    ["Articles", 0, 1, 6],
  ];
  
export const options = {
    title: "OptikAI development",
    chartArea: { width: "50%" },
    isStacked: true,
    hAxis: {
      title: "Total achievements",
      minValue: 0,
    },
    vAxis: {
      title: "Achievements",
    },
  };

export default function Data() {
    return (
        <>
            <div className={styles.groupInformation}>
                <TitleDescription title="Group information" booleanController={false}>
                    Our dynamic team is composed of experienced entrepreneurs, skilled engineers, physics, and chemists. With a combined expertise  in Optical, Sensorial Food, Analytical Chemistry, Computational methods, we are dedicated to developing the technology that  integrates creativity, efficiency and impartiality in the roasting coffee process.  
                </TitleDescription>

                <Chart
                    chartType="BarChart"
                    width="60vw"
                    height="60vh"
                    data={data}
                    options={options}
                />
            </div>

            <div className={styles.trajectory}>
                <TitleDescription title="Trajectory" booleanController={true}>
                    To finish the undergraduate degree in Chemistry at IQSC/USP  of Dr. Bruno Pereira, it was necessary to create a monograph about     the insights into the sensorial quality of coffee (essentially data science). During the monograph presentation, for one exam,  Prof. Dr. Jarbas Caiado de Castro Neto initiated a discussion about the challenges of increasing the efficiency of present   technologies in coffee roasting, and Prof. Dra. Agnieszka Joanna Pawlicka Maule added some cases that attempted to apply sensory  science with polymer technologies, highlighting the difficulties of decreasing the price and lifetime of the sensors. At this    point, Bruno began to ponder some questions about the possibilities and pushed himself to think outside the box, as he was     already working with the head of R&D at Espressiata Coffee. A few months later, he decided to leave the R&D position and embark     on a new area of his life. After meeting with Prof. Dr. Vanderlei S. Bagnato and Prof. Dr. Jarbas Caiado de Castro Neto, it was     decided to start a new position as a post-doctoral researcher in the Optical Group at IFSC/USP. He had a few months to  scientifically prove the proof-of-concept that green coffee beans exhibit fluorescence and that it's possible to identify    sensorial differences using mathematics. After this point, the brand OptikAI was created, and partnerships were formed with    EMBRAPII, SEBRAE, USP, and FAPESP to focus on developing Brazilian technology applied to the sensorial quality of brewed coffee,   using only spectroscopy and AI to evaluate the quality of coffee beans. 
                </TitleDescription>
                
                <img className={styles.coffeeImage} 
                    src="coffeeSomme.png" 
                    alt="coffee sommelier" 
                />

            </div>

            <div className={styles.doubts}>
                <img className={styles.doubtsImg} 
                    src="PeopleDoubts.jpeg" 
                    alt="doubts" 
                />

                <TitleDescription title="Motivation" booleanController={true}>
                    Currently, sensorial analysis of brewed coffee evaluates 11 attributes and requires approximately 1 hour to complete. The       inflationary pressure within the coffee roasting industry has necessitated a reduction in the time of this process.     Additionally, the exclusive dependence on the health and experience of human tasters can sometimes lead to impartiality   issues,   as human perception is subjective. The holistic nature of the evaluation process contributes to inefficiencies when     attempting    to reduce time or accommodate tasters with poor health. Year-over-year, farms are improving their management  practices, which  directly impacts the quality of green coffee beans. However, the industry continues to rely on the same human  analysis methods   without significant changes. The need for increased efficiency and agility in the roasting process to     guarantee quality has   motivated the development of scientific methodologies and impartial analysis to assist coffee tasters   and industries during   roasting production, regardless of the volume or number of samples evaluated daily. This approach     motivating the OptikAI  day-by-day.
                </TitleDescription>
            </div>
        </>
    )
}