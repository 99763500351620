import classNames from "classnames";
import styles from "./TextField.module.scss"
import { useState } from "react";
import countries from "data/countries";
import {FormControl, MenuItem, Select, SelectChangeEvent} from "@mui/material"

interface Props {
    label: string;
    type: string;
    placeHolder: string;
    value: string;
    onChanging: React.Dispatch<React.SetStateAction<string>>;
    boxShadow: boolean;
}

export default function TextField({
    label, 
    value, 
    onChanging, 
    placeHolder, 
    type="text",
    boxShadow
    }: Props) {

    const condition = label.includes("Country");
    const [country, setCountry] = useState("");

    const messageInput = label.toLocaleLowerCase() === "message" ? true : false;

    const onDigit = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChanging(event.target.value);
    }

    const onSelectCountry = (event: SelectChangeEvent<string>) => {
        onChanging(event.target.value)
    }

    return (
        <>
            <div className={classNames({
                [styles["input-text"]]: true,
                [styles["input-text__message"]]: messageInput
            })}>
                <label className={styles.label} >{label}</label>
                <input
                    required
                    value={value}
                    onChange={onDigit}
                    className={classNames({
                        [styles.input]:true,
                        [styles.input__message]: messageInput,
                        [styles.input__boxShadow]: boxShadow
                    })} 
                    type={type}
                    placeholder={placeHolder}
                />
            </div>
            
            {condition && 
            <div className={classNames({
                [styles.dropdown]: true,
                [styles["dropdown__cond"]]: condition
            })}>
                <div className={styles.select}>
                    <FormControl 
                        margin="dense" 
                        fullWidth    
                    >
                        <Select
                            value={country}
                            labelId="select-countries"
                            onChange={(event) => {
                                setCountry(event.target.value);
                                onSelectCountry(event);
                            }}            
                        >
                        {countries.filter(data => {
                            return data.name.toLowerCase().includes(value.toLowerCase())
                        }).map((data, index) => {
                            return (
                                <MenuItem key={index} value={data.name}>
                                    {data.name}
                                </MenuItem>
                            )
                        })}
                        </Select>
                    </FormControl>
                    <h4 className={styles.textPad}>select your country</h4>
                </div>    
            </div>
            }
        </>
    )
}