import React from "react"
import styles from "./Main.module.scss"
import PartnerBanner from "./Partners"
import thirdOnuOds from "../../../src/assets/thirdOnuOds.jpg"
import ninethOnuOds from "../../../src/assets/ninethOnuOds.jpg"
import twelvethOnuOds from "../../../src/assets/twelvethOnuOds.jpg"

export default function Main() {

    const imageArray = [
        thirdOnuOds,
        ninethOnuOds,
        twelvethOnuOds
    ]

    return(
    <React.Fragment>
        <div className={styles.main}>
            <div className={styles.aligner}>
                <div className={styles.goalsObjectives}>
                    <div className={styles["text-content"]}>
                        <h1>
                            Goals
                        </h1>
                        <p>
                            Coffee + AI + Optical = Sensorial quality evaluation
                        </p>
                    </div>

                </div>

                <div className={styles.goalsObjectives}>
                    <h2 className={styles.onuPilars}>ONU objectives</h2>
                    <p>All developments are foucused on three pilars</p>
                    <div className={styles.onuOds}>
                        {imageArray.map((image, index) => {
                            return (
                                <img
                                    key={index}
                                    className={styles.odsImg}
                                    src={image} 
                                    alt="" 
                                />
                            )
                        })}
                    </div>
                </div>
            </div>


            <img
                className={styles.mainPageImage}
                src="./realibility.jpg" 
                alt="imagem do café" 
            />

        </div>
        <PartnerBanner/>
    </React.Fragment>
    )
}