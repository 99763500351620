import { token } from "../http";
import { create } from "zustand";

export interface IUserLoginState {
    userMode: {userLogin: boolean};
    setUserMode: (mode: boolean) => void;
}

const initialState = token != null;

export const userLoginState = {
    user: {
        userLogin: initialState
    }
}

export const useUserLoginStore = create<IUserLoginState>((set) => ({
    userMode: userLoginState.user,

    setUserMode: (newUserMode: boolean) => {
        set({
            userMode: {
                userLogin: newUserMode
            }
        });
    },
}));