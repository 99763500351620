import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react"
import { useUserLoginStore } from '../../store';


interface Iprotector {
    children: React.ReactNode;
}

export default function RouteProtector({children}: Iprotector) {

    const userLoginMode = useUserLoginStore((state) => state.userMode.userLogin);
    const navigate = useNavigate();

    useEffect(() => {
        if (!userLoginMode) {
            navigate("/");
        }
    }, []);

    return (
        <>
            {children}
        </>
    );
};
