import styles from "./PaymentModal.module.scss"
import Modal from "Components/Modal";

interface IPaymentModal {
    title: string;
    open: boolean;
    onClose: () => void;
    qrcode:string;
}

export default function PaymentModal({
    open, onClose, title, qrcode
}:IPaymentModal) {
    
    const descriptions: Array<string> = [
        "Great computacional power",
        "Cloud integration",
        "Hardware and software support team"
    ];

    return (
        <Modal
            onClose={onClose}
            open={open}
            title={title}
        >
            <div className={styles.qrcodeContainer}>

                <h2 className={styles.productName}>
                    Ai Sensory Coffee Analyser N1
                </h2>

                <div className={styles.qrcodeAndImgContainer}>
                    <img
                        className={styles.productImg} 
                        src="comingSoon.jpeg" 
                        alt="ficticious product" 
                    />
                    <img
                        className={styles.qrcodeImage}
                        src={qrcode} 
                        alt="efi pix qrcode generated"
                    />
                </div>

                <p className={styles.paymentInstruc}>
                    Get the product by this qr code or through the credit card !
                </p>

                <div className={styles.aboutContainer}>
                    <h3 className={styles.aboutProduct}>
                        About the product
                    </h3>

                    <ul>
                        {descriptions.map(description => {
                            return (
                                <li className={styles.aboutItem} key={description}>
                                    {description}
                                </li>
                            )
                        })}
                    </ul>
                </div>

            </div>
        </Modal>
    )
}