import axios, { InternalAxiosRequestConfig } from "axios";
import { IUser } from "interfaces/IUser";

const http = axios.create({
    baseURL: "https://backend.optikai.com.br",
    headers: {
        Accept: "application/json",
        Content: "application/json"
    }
});

http.interceptors.request.use(function (config: InternalAxiosRequestConfig<string>) {
    
    const token = sessionStorage.getItem('token');
    
    if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
}, function (error) {

    console.log('Ocorreu um erro em algum dos interceptadores do axios');
    return Promise.reject(error);
});

export default http;

export const getUserList = async () => {
    const response = await http.get<Array<IUser>>("login");
    return response.data;
}

export const token = sessionStorage.getItem("token");