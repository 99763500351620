import styles from "./Shop.module.scss"
import { useState } from "react"
import ModalLogin from "Components/ModalLogin";
import ModalRegister from "Components/ModalRegister";
import { useUserLoginStore } from "store";
import ProductDescription from "Components/ProductDescription";
import PaymentModal from "Components/PaymentModal";

export default function Shop() {

    const [modalOpen, setModalOpen] = useState(false);
    const [registerModalOpen, setRegisterModalOpen] = useState(false);
    const setUserLoginMode = useUserLoginStore((state) => state.setUserMode);
    const [paymentModal, setPaymentModal] = useState<boolean>(false);
    const [qrcode, setQrcode] = useState("");

    const onLogin = () => {
        setModalOpen(false);
    }

    const createRegister = () => {
        setRegisterModalOpen(false);
    }

    return (
        <div className={styles.allComponents}>
            <h1 className={styles.shopTitle}>
                Check out our products
            </h1>
            
            <ProductDescription
                modalOpen={modalOpen} 
                setModalOpen={setModalOpen}
                setPaymentModalOpen={setPaymentModal}
                setQrcode={setQrcode}
            />

            <ModalLogin 
                    title="Login"
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onRegister={() => {
                        setRegisterModalOpen(true);
                        setModalOpen(false);
                    }}
                    onLogin={onLogin}
                    loginState={setUserLoginMode}
                />
                
                <ModalRegister 
                    title="Register"
                    open={registerModalOpen}
                    onClose={() => setRegisterModalOpen(false)}
                    onRegister={createRegister}
                />

                <PaymentModal
                    title="Get now !"
                    open={paymentModal}
                    onClose={() => setPaymentModal(false)}
                    qrcode={qrcode}
                />
        </div>
    )
}