import Button from "Components/Button"
import styles from "./ProductDescription.module.scss"
import { useUserLoginStore } from "store";
import classNames from "classnames";
import http from "../../http";

interface IModalProperties {
    modalOpen: boolean;
    setModalOpen: (value: boolean) => void;
    setPaymentModalOpen: (value: boolean) => void;
    setQrcode: (qrcode:string) => void;
}

interface IQrCodeResponse {
    qrcode: string;
    imagemQrcode: string;
    linkVisualizacao: string;
}

export default function ProductDescription({
    modalOpen, 
    setModalOpen,
    setPaymentModalOpen,
    setQrcode
}: IModalProperties) {

    const userLoginMode = useUserLoginStore((state) => state.userMode.userLogin);

    const getQrCode = async () => {
        await http.get<IQrCodeResponse>("oauth")
            .then((response) => {
                setQrcode(response.data.imagemQrcode);
            }).catch((error) => {
                if (error.response.data?.message) {
                    console.log(error.response.data?.message);
                } else {
                    console.log("Unexpected error ocourred, contact the support team");
                }
            })
    }

    return (
            <div className={styles.shopContainer}>
                <div className={styles.productContainer}>
                    <h3 className={styles.productTitle}>
                        AI Sensory Coffee Analyzer (N1)
                    </h3>

                    <div className={styles.informationContainer}>
                        <img 
                            className={styles.comingSoonImg}
                            src="comingSoon.jpeg" 
                            alt="coming soon" 
                        />

                        <div className={styles.shopDescription}>
                            <h2 className={styles.productDescriptionTitle}>
                                Description
                            </h2>
                            <p className={styles.productDescription}>
                                The AI Sensory Coffee Analyzer is a cutting-edge solution that leverages artificial intelligence to objectively evaluate the sensory qualities of coffee. By analyzing key parameters such as aroma, flavor, acidity, body, and aftertaste, our system provides precise and reliable insights to enhance quality control, streamline production processes, and optimize the overall coffee experience
                            </p>
                            <div className={classNames({
                                [styles.buyNowContainer]:true,
                                [styles.buyNowContainer__onLogin]:userLoginMode
                            })}>
                                    <p className={classNames({
                                        [styles.salesTeamPar]: !userLoginMode,
                                        [styles.salesTeamPar__block]:userLoginMode
                                    })}>
                                        Contact the sales team if you are interested
                                    </p>

                                <Button
                                    onClickAction={userLoginMode
                                        ? () => {
                                            getQrCode();
                                            setPaymentModalOpen(true);

                                        }
                                        : () => setModalOpen(!modalOpen)
                                    } 
                                    text={userLoginMode? "Add to shopping bag": "Get price"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}