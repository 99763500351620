import styles from "./Button.module.scss"

interface Props {
    text:string;
    onClickAction?: () => void;
}

export default function Button({text, onClickAction}: Props) {
    return (
        <button onClick={onClickAction} className={styles.button}>
            {text}
        </button>
    )
}