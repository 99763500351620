import React from "react";
import styles from "./TitleDescription.module.scss"
import classNames from "classnames";

interface ITitleDescriptionProps {
    title: string;
    children: React.ReactNode;
    booleanController: boolean;
}

export default function TitleDescription({title, children, booleanController}: ITitleDescriptionProps) {
    return (
        <div className={classNames({
            [styles.titleDescriptionContainer]:true
        })}>
            <h2>
                {title}
            </h2>
            
            <p>
                {children}
            </p>
        </div>

    )   
}