import Button from "Components/Button"
import styles from "./Contact.module.scss"
import TextField from "Components/TextField"
import React, { useState } from "react"
import http from "../../http";

export default function Contact() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [userMessage, setUserMessage] = useState("");

    const stateArraySetter = [setName, setEmail, setUserMessage];
    
    const arrayData = [
        {
            label:"Name",
            type: "text",
            placeHolder: "insert your name",
            value: name,
        },
        {
            label:"Contact",
            type: "text",
            placeHolder: "Your email",
            value: email,
        },
        {
            label:"message",
            type: "text",
            placeHolder: "",
            value: userMessage,
        }
    ]

    const onSave = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        http.post("contact",{name, email, userMessage})
            .then((response) => {
                console.log(response);
            }).catch((error) => {
                console.log(error);
            })

        cleanForm(stateArraySetter);
    }

    function cleanForm(stateArraySetter: 
        Array<React.Dispatch<React.SetStateAction<string>>>) {
        for (let element of stateArraySetter) {
            element("");
        }
    }
    
    return (
        <section className={styles["input-container"]}>
            <div className={styles["div-container"]}>
                <form onSubmit={onSave}>
                    <h2 className={styles.contact}>Contact us</h2>
                        {arrayData.map((info, index) => {
                            return (
                                <TextField
                                    boxShadow={true}
                                    key={index}
                                    onChanging={str => stateArraySetter[index](str)}
                                    value={info.value}
                                    label={info.label}
                                    type={info.type}
                                    placeHolder={info.placeHolder}
                                />
                            )
                        })}
                        <div className={styles.buttonPadding}>
                            <Button text={"Send message"}/>
                        </div>
                </form>
            </div>
        </section>
    )
}