import Modal from "Components/Modal";
import styles from "./ModalRegister.module.scss"
import React, { useState } from "react";
import TextField from "Components/TextField";
import classNames from "classnames";
import http from "../../http"

interface ModalRegisterProps {
    title: string;
    open:boolean;
    onClose: () => void;
    onRegister: () => void;
}

const ModalRegister = ({ title, open, onClose, onRegister}: ModalRegisterProps) => {

    const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newRegister = {
            email: userName,
            country,
            cpfCnpj,
            password
        }

        if (password !== confirmation) {
            alert("The fields password and confirmation must be equal");
            return;
        }
        
        http.post("register", newRegister)
            .then(response => {
                alert("user successfully registered");
                cleanForm();
            })
            .catch(error => {
                alert(error);
            })
    }

    const cleanForm = () => {
        registerInfo.forEach(field => {
            return field.onChanging("");
        })
    }

    const [userName, setUserName] = useState("");
    const [country, setCountry] = useState("");
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [password, setPassword] = useState("");
    const [confirmation, setConfirmation] = useState("");

    const registerInfo = [
        {
            label: "Username",
            boxShadow: false,
            value: userName,
            placeHolder: "ex: email@email.com",
            onChanging: setUserName,
            type: "text"
        },
        {
            label: "Country",
            boxShadow: false,
            value: country,
            placeHolder: "",
            onChanging: setCountry,
            type: "text"
        },
        {
            label: "CPF/CNPJ",
            boxShadow: false,
            value: cpfCnpj,
            placeHolder: "",
            onChanging: setCpfCnpj,
            type: "text"
        },
        {
            label: "Password",
            boxShadow: false,
            value: password,
            placeHolder: "******",
            onChanging: setPassword,
            type: "password"
        },
        {
            label: "Password confirmation",
            boxShadow: false,
            value: confirmation,
            placeHolder: "******",
            onChanging: setConfirmation,
            type: "password"
        },
    ]

    return (
        <Modal
            title={title}
            open={open}
            onClose={onClose}
        >
            <div className={styles.registerContainer}>
                <img
                    className={styles.loginImage}
                    src="/imageLogin.jpg" 
                    alt="Login do usuário"
                />
                <div className={styles.formModalContainer}>
                    <form
                        className={styles.formStyle} 
                        onSubmit={(event) => {
                            onSubmitForm(event);
                            onRegister();
                        }}
                    >
                        {registerInfo.map(info => {
                            return (
                                <TextField
                                    key={info.label}
                                    label={info.label}
                                    boxShadow={info.boxShadow}
                                    value={info.value}
                                    placeHolder={info.placeHolder}
                                    onChanging={info.onChanging}
                                    type={info.type}
                                />
                            )
                        })}
                        <button
                            className={classNames({
                            [styles.registerButton]:true,
                            [styles.registerButton__bottom]:true
                        })}>
                            Register
                        </button>
                        <hr className={styles.hLine} />
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default ModalRegister;