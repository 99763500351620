import Modal from "Components/Modal";
import styles from "./ModalLogin.module.scss"
import React, { useState } from "react";
import TextField from "Components/TextField";
import classNames from "classnames";
import http from "../../http";

interface RegisterModalProps {
    title:string;
    open:boolean;
    onClose: () => void;
    onRegister: () => void;
    onLogin: () => void;
    loginState: (value: boolean) => void;
}

const ModalLogin = ({ title, open, onClose, onRegister, onLogin, loginState}: RegisterModalProps) => {

    const cleanForm =() => {
        setUserName("");
        setPassword("");
    }

    const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const user = {
            email: userName,
            password
        };

        http.post("login", user)
            .then(response => {
                sessionStorage.setItem("token",response.data.accessToken);
                sessionStorage.setItem("email", response.data.email);
                loginState(true);
                alert("login done successfully");
                cleanForm();
            })
            .catch(error => {
                if (error?.response?.data?.message) {
                    alert(error.response.data.message);
                } else {
                    alert("unexpected error ocurred, please contact the support team");
                }
            });
    }

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const loginInfo = [
        {
            label: "Username",
            boxShadow: false,
            value: userName,
            placeHolder: "ex: email@email.com",
            onChanging: setUserName,
            type: "text"
        },
        {
            label: "Password",
            boxShadow: false,
            value: password,
            placeHolder: "*****",
            onChanging: setPassword,
            type: "password"
        }
    ]

    return (
        <Modal
            title={title}
            open={open}
            onClose={onClose}
        >
            <div className={styles.loginContainer}>
                <img
                    className={styles.loginImage}
                    src="/imageLogin.jpg" 
                    alt="Login do usuário"
                />
                <div className={styles.formModalContainer}>
                    <form
                        className={styles.formStyle} 
                        onSubmit={(event) => {
                            onSubmitForm(event);
                            onLogin();
                        }}
                    >
                        {loginInfo.map(info => {
                            return (
                                <TextField
                                    key={info.label}
                                    label={info.label}
                                    boxShadow={info.boxShadow}
                                    value={info.value}
                                    placeHolder={info.placeHolder}
                                    onChanging={info.onChanging}
                                    type={info.type}
                                />
                            )
                        })}
                        <button className={classNames({
                            [styles.loginButton]:true,
                            [styles.loginButton__bottom]:true
                        })}>
                            Login
                        </button>
                        <hr className={styles.hLine} />
                        <div className={styles.registerContainer}>
                            <h3>Don't have registration yet? </h3>
                            <button
                                onClick={onRegister} 
                                className={styles.loginButton}>
                                Register
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default ModalLogin;